import React, { useState } from "react"
import { useWindowSize } from "react-use"
import Layout from "../../components/layout.js"
import Seo from "../../components/seo.js"

import SignInTeacher from "../../components/SignInTeacher/index.js"
import SignUpTeacher from "../../components/SignUpTeacher/SignUpTeacherContainer.js"
import useSSRStorage from "../../hooks/useSSRStorage.js"
import { isJWTExpired } from "../../services/helpers.js"
import { navigate } from "gatsby"

const TeacherPage = () => {
  const isBrowser = typeof window !== "undefined" // check if executing instance is the browser or the building process
  const [signIn, setSignIn] = useState(true)
  const { height } = useWindowSize()
  const [jwt] = useSSRStorage("jwt_teacher")
  if (isBrowser) {
    if (jwt && !isJWTExpired(jwt)) {
      navigate("/teacher/profile/")
    } else {
      window.localStorage.removeItem("jwt_teacher")
    }
  }

  const toggleSignInFlag = () => setSignIn((current) => !current)

  return (
    <Layout layoutHeight={height} checkForProfile={false}>
      <Seo title="Lehrkraft Zugang" />
      {signIn ? (
        <SignInTeacher toggleSignInFlag={toggleSignInFlag} />
      ) : (
        <SignUpTeacher toggleSignInFlag={toggleSignInFlag} />
      )}
    </Layout>
  )
}

export default TeacherPage
