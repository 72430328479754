import { GraphQLClient } from "graphql-request"

let graphqlClient = new GraphQLClient(process.env.GATSBY_GRAPHQL_API_URL)

export const resetGraphQLClient = () => {
  graphqlClient = new GraphQLClient(process.env.GATSBY_GRAPHQL_API_URL)
  return graphqlClient
}

export default graphqlClient
