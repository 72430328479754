import React from "react"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"

import { TextInput, PasswordInput, BoolInput } from "../UserProfileFormBlocks"
import Tali from "../Tali"
import { Link, navigate } from "gatsby"

import { SignUpTeacherLayout } from "../../styles/common"

const SignUpTeacherDisplay = ({
  registrationErrorMessage,
  handleChange,
  handleSubmit,
  toggleSignInFlag,
}) => {
  const { width } = useWindowSize()

  return (
    <SignUpTeacherLayout as="form" onSubmit={handleSubmit}>
      <Tali
        size={(width / 800) * 0.8}
        mood="happy"
        position="top"
        float={false}
        messageStyle="glow"
      >
        <p className="tali-question">
          Alternativ kannst Du hier ein neues Konto für Deine Klasse anlegen:
        </p>
      </Tali>
      <span style={{ color: "red" }}>{registrationErrorMessage}</span>
      <TextInput
        handleChange={handleChange}
        id="teacher-name"
        name="name"
        label="Name"
        required
        placeholder="Name"
        type="text"
      />
      <TextInput
        handleChange={handleChange}
        id="teacher-email-signup"
        name="email"
        label="E-Mail"
        required
        placeholder="E-Mailadresse"
        error={"test"}
        type="email"
      />
      <PasswordInput
        handleChange={handleChange}
        id="teacher-password-signup"
        name="password"
        label="Passwort"
        required
        placeholder="Passwort"
      />
      <TextInput
        handleChange={handleChange}
        id="teacher-school"
        name="school"
        label="Schule"
        required
        placeholder="Schule"
        type="text"
      />
      <div>
        <BoolInput
          handleChange={handleChange}
          id="teacher-privacy-accept"
          name="terms-accept"
          label={
            <span>
              Ich habe die{" "}
              <Link className="document-link" to="/datenschutz">
                Datenschutzerklärung
              </Link>{" "}
              gelesen.
            </span>
          }
          required
        />
        <BoolInput
          handleChange={handleChange}
          id="teacher-terms-accept"
          name="terms-accept"
          label={
            <span>
              Es gelten unsere{" "}
              <Link className="document-link" to="/agb">
                Allgemeine Geschäftsbedingungen und Nutzungsbedingungen
              </Link>
              .
            </span>
          }
          required
        />
      </div>
      <nav>
        {/* <Link className="button button--medium" to="/">
          Zurück
        </Link> */}
        <button
          className="button back-button button--medium"
          onClick={() => navigate("/")}
        >
          Zurück
        </button>
        <button
          style={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
          className="continue-button button button--medium"
        >
          Registrieren
        </button>
      </nav>
      <button
        onClick={() => {
          toggleSignInFlag()
        }}
        className="button sign-in-button button--medium"
      >
        {"Ich habe bereits ein Konto."}
      </button>
    </SignUpTeacherLayout>
  )
}

SignUpTeacherDisplay.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default SignUpTeacherDisplay
