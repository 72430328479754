import React, { useState } from "react"

import SignUpTeacherDisplay from "./SignUpTeacherDisplay.js"
import { useMutation } from "react-query"
import { gql } from "graphql-request"
import { navigate } from "gatsby"
import graphqlClient from "../../services/graphql-client.js"
import useSSRStorage from "../../hooks/useSSRStorage.js"

const SIGN_UP_TEACHER = gql`
  mutation SignUpTeacher(
    $email: String!
    $name: String!
    $password: String!
    $school: String!
  ) {
    teacherSignUp(
      input: {
        email: $email
        name: $name
        password: $password
        school: $school
      }
    ) {
      jwtToken
    }
  }
`

const SignUpTeacherContainer = ({ toggleSignInFlag }) => {
  const [teacher, setTeacher] = useState({})
  const [signUpErrorMessage, setSignUpErrorMessage] = useState()
  const [, setJwt] = useSSRStorage("jwt_teacher", null)

  const signUpTeacherMutation = useMutation(
    (variables) => graphqlClient.request(SIGN_UP_TEACHER, variables),
    {
      onSuccess: (data) => {
        console.log(data)
        if (data.teacherSignUp.jwtToken) {
          const jwt = data.teacherSignUp.jwtToken
          console.log(`jwt: ${jwt}`)
          graphqlClient.setHeader("authorization", `Bearer ${jwt}`)
          window.localStorage.removeItem("jwt_teacher")
          window.localStorage.removeItem("jwt_student")
          setJwt(jwt)
          navigate("/teacher/profile/")
        }
      },
      onError: (error) => {
        if (error.response.errors[0].message.startsWith("duplicate key")) {
          setSignUpErrorMessage("Diese E-Mailadresse ist bereits registriert.")
        }
      },
    }
  )

  const handleChange = ({ target: { name, value } }) => {
    setTeacher((teacher) => ({
      ...teacher,
      [name]: value,
    }))
    // TODO: do some input-validation here
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // console.log("...handleSubmit...")
    // console.log(teacher)

    signUpTeacherMutation.mutate({
      email: teacher.email,
      name: teacher.name,
      password: teacher.password,
      school: teacher.school,
    })
  }

  return (
    <SignUpTeacherDisplay
      registrationErrorMessage={signUpErrorMessage}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      toggleSignInFlag={toggleSignInFlag}
    />
  )
}

SignUpTeacherContainer.propTypes = {}

export default SignUpTeacherContainer
