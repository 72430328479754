import React, { useState } from "react"

import SignInTeacherDisplay from "./SignInTeacherDisplay.js"
import { useMutation } from "react-query"
import { gql } from "graphql-request"
import { navigate } from "gatsby"
import graphqlClient from "../../services/graphql-client.js"
import useSSRStorage from "../../hooks/useSSRStorage.js"

const SIGN_IN_TEACHER = gql`
  mutation ($email: String!, $password: String!) {
    teacherSignIn(input: { email: $email, password: $password }) {
      jwtToken
    }
  }
`

const SignInTeacherContainer = ({ toggleSignInFlag }) => {
  function ValidateEmail(mail) {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)
  }

  function ValidatePassword(password) {
    return password.length > 3
  }

  const isBrowser = typeof window !== "undefined" // check if executing instance is the browser or the building process
  const [loginCredentials, setLoginCredentials] = useState({})
  const [, setJwt] = useSSRStorage("jwt_teacher", null)

  const signInTeacherMutation = useMutation(
    (variables) => graphqlClient.request(SIGN_IN_TEACHER, variables),
    {
      onSuccess: (data) => {
        const jwt = data.teacherSignIn.jwtToken
        if (jwt && isBrowser) {
          console.log(`Bearer ${jwt}`)
          graphqlClient.setHeader("authorization", `Bearer ${jwt}`)
          window.localStorage.removeItem("jwt_teacher")
          window.localStorage.removeItem("jwt_student")
          setJwt(jwt)
          navigate("/teacher/profile/")
        }
      },
    }
  )

  const handleChange = ({ target: { name, value } }) => {
    setLoginCredentials((loginCredentials) => ({
      ...loginCredentials,
      [name]: value,
    }))
    // TODO: do some input-validation here
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (
      ValidateEmail(loginCredentials.email) &&
      ValidatePassword(loginCredentials.password)
    ) {
      signInTeacherMutation.mutate({
        email: loginCredentials.email,
        password: loginCredentials.password,
      })
    }
  }

  return (
    <>
      <SignInTeacherDisplay
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        signInTeacherMutation={signInTeacherMutation}
        toggleSignInFlag={toggleSignInFlag}
      />
    </>
  )
}

SignInTeacherContainer.propTypes = {}

export default SignInTeacherContainer
